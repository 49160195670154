/* eslint-disable max-len, indent */

// Current version of this website bundle
const APP_CUR_REVISION = 20240112;

const appLinks = {
  root: "/",
  account: "/account",
  gen: "/ai-writer",
  sum: "/ai-summarizer",
  hl: "/ai-headline-generator",
  para: "/ai-paraphraser",
  sum_api: "/ai-text-summarizer-api",
  hl_api: "/ai-headline-generator-api",
  para_api: "/ai-paraphrasing-api",
  gen_api: "/ai-text-generator-api",
  subs: "/pricing",
  check: "/checkout",
  signin: "/signin",
  signupconfirm: "/signup-confirmation",
  dev: "/developer-api",
  story: "/ai-story-writer",
  blog: "https://blog.sassbook.com",
};

const appLinkTitles = {
  root: "Sassbook AI creates software to automate text generation, text " +
    "understanding, and writing improvement using artificial intelligence",
  account: "User account information, notifications, upgrades, and billing information management",
  gen: "The best automatic text generator (AI writer) that produces quality " +
    "text, just like a human",
  story: "The AI story writer that delivers the best of automatic AI story " +
    "generation every time",
  sum: "Generate high quality, human-like automatic summaries with this " +
    "AI text summarizer online software",
  hl: "The AI headline generator that automatically generates catchy titles " +
    "and headlines for your content",
  para: "The AI paraphrasing tool that automatically paraphrases text with control " +
    "over the amount of rewrite as well as the length of AI paraphrasing",
  para2: "The AI article paraphrasing tool that that rewrites articles " +
    "to adapt to any use case and audience",
  signin: "Sign in to use Sassbook AI content automation applications (AI Writer, " +
    "AI Summarizer, AI Paraphraser, AI Headline Generator, AI Story Writer) " +
    "without restrictions",
  subs: "Pricing of Sassbook AI content automation applications: AI Writer, " +
    "AI Story Writer, AI Summarizer, AI Paraphraser, and AI Headline Generator",
  check: "Checkout the selected subscription",
  gen_api: "Sassbook AI Content Generator API for automated text writing workflows",
  sum_api: " Automate your text summarization workflows with Sassbook AI " +
    "Summarizer API, the abstractive summary generator API",
  hl_api: "Automate your headline generation workflows with Sassbook AI " +
    "Headline Generator API or the AI Title Generator API",
  para_api: "Automate your paraphrasing workflows with Sassbook AI " +
    "Paraphraser API (AI Rewriter API)",
  blog: "https://blog.sassbook.com",
  dev: "Sassbook AI developer API page features AI summarization API, " +
    "AI Paraphraser API, AI Headline Generator API and the AI writer API",
};

const learnLinks = {
  root: "/learn-more",
  gen: "/learn-more-ai-writer",
  story: "/learn-more-ai-story-writer",
  sum: "/learn-more-ai-summarizer",
  hl: "/learn-more-ai-title-generator",
  para: "/learn-more-ai-paraphrasing-tool",
  blog: "https://blog.sassbook.com",
};

const learnLinkTitles = {
  root: "More about AI writer, AI Story Writer, AI Summary Generator, AI " +
    "Paraphraser, and AI Headline Generator apps by Sassbook AI",
  gen: "Learn more about Sassbook AI Writer (AI content generator) and how " +
    "to make the best use of its automatic text generation capabilities",
  story: "Learn more about Sassbook AI Story Writer (AI story generator) and " +
    "how to make the best use of its automatic story generation capabilities",
  sum: "Learn more about the abstractive text summarization capabilities of " +
    "the Sassbook AI Summarizer and why it is the best text summarizer out there",
  sum2: "Learn more about Sassbook AI Summarizer (AI summary generator) and " +
    "how to make the best use of its human-like text summarisation capabilities",
  hl: "Learn more about Sassbook AI Headline Generator (automatic title generator) and how to make " +
    "your content stand out with superb headlines",
  para: "Learn more about Sassbook AI Paraphraser (AI rewriter) and how to make " +
    "the paraphrasing tool to rewrite the text for you use case and intended audience",
  para2: "Learn more about Sassbook AI Paraphraser (paraphrasing tool) and how to make " +
    "adapt your writing to the intended application scenario by rewriting the text",
  hl2: "Learn more about Sassbook AI Headline Generator (AI title generator) " +
    "and how to make your content shine with fitting headlines, titles, or taglines",
  blog: "Blog of Sassbook AI. Visit to understand more about the company and its automatic " +
    "content generation, summarization, paraphrasing, and story writing applications",
};

const helmet = {
  author: "Sassbook AI",
  sum: {
    title: "AI Summarizer | Modern, automatic text summary generator",
    desc: "Sassbook AI Text Summarizer is a modern summary generator powered by deep AI." +
      "Create great abstractive text summaries for free, just like a human would.",
    keywords: "best text summarizer, summary generator, text summarizer, " +
      "automatic text summarizer, extractive summarization, abstractive " +
      "summarization, human-like ai summary, ai text summary, automatic text " +
      "summary, ai summary with paraphrasing, automatic summary with " +
      "rephrasing, summarizing ai, resoomer alternative, quillbot alternative, " +
      "academic document summaries, summary of teaching material, summary of " +
      "study material, summarizer,  summerizer, summary of government documents, " +
      "summary of official documents, document summarization, ai text summarisation " +
      "text summarisation, auto summarise text, seo summarization, automated " +
      "summaries, abstractive summary, lecture summary, text shortener, tl;dr generator,",
  },
  sum_api: {
    title: "AI Text Summarizer API | Automate Text Summary Generation",
    desc: "Best AI text summarizer API to fully automate text summary generation " +
      "workflows. The API supports both extractive and abstractive summarization",
    keywords: "Best AI Summarizer api, ai summary generator api, text summarizer api, automatic text summarizer api, " +
      "extractive summarization api, abstractive summarization api, ai text summary api, " +
      "automatic text summary api, summarizing ai api, text summarisation api, " +
      "automatic text summarisation api, research summarization api, ai abstracts, " +
      "transcript summarization api, document summarization api, article summary generator API.",
  },
  hl_api: {
    title: "AI Headline Generator API | Automate Headline (Title) Generation",
    desc: "Best AI headline generator API to fully automate headline generation " +
      "workflows. Generate titles, headlines, and taglines with the AI API.",
    keywords: "Best AI headline generator API, best AI title generator API, headline " +
      "generator API, automatic title generator API, automatic headline generator API, " +
      "API to generate headlines, API to generate titles, headline API, title API, " +
      "AI tagline generator API, automatic tagline generator API, tagline API",
  },
  gen: {
    title: "Sassbook AI Writer | Versatile AI Text Generator",
    desc: "The AI writer is an AI text generator supporting a wide variety of use " +
    "cases. Start writing and let the AI produce several completions for your " +
    "articles, blogs, social media posts, marketing copy, emails, and more.",
    keywords: "AI Writer, AI text generator, ai article writer, automatic text generator, auto writer, " +
      "writing ai, ai writing, best ai writer, artificial intelligence writer, AI content generator, " +
      "AI story writer, AI blog writer, ai website content generator, automatic web content generator, " +
      "ai seo writer, ai copywriting, ai copywriter, ai website content, ai article generator, ",
  },
  story: {
    title: "Sassbook AI Story Writer | Top-notch AI Story Generator",
    desc: "The AI story generator with several preset story genres to create authentic stories " +
      "rapidly. Ban writer's block with this AI story writer.",
    keywords: "AI Story Writer, AI story generator, ai fiction writer, automatic story generator, auto story writer, " +
      "story writing ai, ai story writing, best ai story writer, artificial intelligence story writer, " +
      "ai that generates stories, ai that writes stories, ai thriller story, ai classic story, ai sci-fi story," +
      "ai humorous story, best ai story writer, best ai story generator, ai story writer generator",
  },
  gen_api: {
    title: "Text Generation API: Automate text generation workflows",
    desc: "Advanced AI content generator API that helps automate content " +
      "generation workflows. The Sassbook AI text generator API caters to diverse domains.",
    keywords: "AI Writer API, AI content generator API, ai article writer API, " +
      "automatic text generator api, auto writer api, best ai writer api, " +
      "ai copy writing api, ai blog writer api, ai story writing api, " +
      "automatic text generator api, text generation API, content generation API",
  },
  hl: {
    title: "AI Headline Generator: Striking headlines for your content",
    desc: "Sassbook AI Headline generator is a modern title generator driven by advanced AI. " +
      "Automatically generate awesome headline titles from any content.",
    keywords: "best headline generator, best title generator, ai headline generator, " +
      "ai title generator, automatic headline generator, automatic title generator, " +
      "automatic headline generation, automatic title generation, automatic " +
      "tagline generation, best tagline generator, ai tagline generator, " +
      "automatic tagline generator, blog title generator, catchy headline " +
      "generator, seo title generator, seo description generator, meta tag generator",
  },
  para: {
    title: "AI Paraphraser: Paraphrasing tool for great AI rewriting",
    desc: "Sassbook AI Paraphraser is a modern paraphrasing tool powered by advanced AI. " +
      "Rewrite text with control over paraphrasing for the target use case.",
    keywords: "best paraphraser, best paraphrasing tool, ai paraphraser, " +
      "ai paraphrasing tool, best rewriter, best rewriting tool, " +
      "ai rewriter, ai rephraser, ai rephrasing tool, ai that paraphrases, " +
      "ai that rewrites, paraphrase sentences, rewrite sentences, " +
      "paraphrase paragraphs, rewrite paragraphs, reword text, rephrase text," +
      "ai paraphrases, quillbot paraphraser alternative, chatgpt paraphrase alternative, " +
      "paraphrasing tool online free, paraphrase generator, paraphrase online, paraphrasing " +
      "tool free, paraphrasing website, paraphrasing tool online, free paraphrasing, " +
      "article paraphraser, article paraphrasing ai, article rewriter, article " +
      "rewriter ai",
  },
  para_api: {
    title: "AI Paraphraser API | Paraphrasing automation at scale",
    desc: "Best AI paraphrasing API to automate paraphrasing and rewriting " +
      "workflows at scale. Integrate the fast paraphrasing API into your " +
      "applications easily.",
    keywords: "Best AI paraphrase API, best AI rewriter API, best paraphrasing " +
      "API, ai paraphrasing api, ai rewriting api, rewording api, ai rephrasing " +
      "API, API to generate paraphrase, API to rewrite text, API to reword text, " +
      "paraphrase generator API",
  },
  root: {
    title: "AI Writer, Text Summarizer, Story Writer, Headline Generator",
    desc: "Best in class AI Writer, AI Story Writer, AI Text Summarizer, and " +
      "AI Headline Generator. Generate unique, quality content, summaries, and headlines with AI.",
    keywords: "AI Writer, AI Summarizer, AI text generator, AI story writer, automatic text generator, " +
      "text generator API, text summarizer API, content generation API, summary generation API, " +
      "writing ai, ai writing, best ai writer, summary generator,  summarizer, AI story generator," +
      "artificial intelligence writer, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, " +
      "summary of official documents, document summarization, ai text summarisation, summerizer, " +
      "ai seo writer, ai copywriting, ai copywriter, content automation, content curation, story writing ai, " +
      "ai headline generator, ai title generator, ai tagline generator, " +
      "ai that generates headlines, ai that generates titles, headline generating ai, " +
      "title generating ai, ai paraphrasing tool, ai paraphraser, ai rewriter, ai rewriting tool, " +
      "ai paraphrase, ai paraphrasing, ai that paraphrases, ai that rewrites",
  },
  account: {
    title: "AI Writer and AI Summarizer: AI writing and summary generation",
    desc: "Best in class AI Writer and AI Summarizer. Automatically generate unique quality articles " +
      "and expert summaries to manage your content generation needs.",
    keywords: "AI Writer, AI text generator, automatic text generator, " +
      "writing ai, ai writing, best ai writer, " +
      "artificial intelligence writer, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, summary generator, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "ai headline generator, ai title generator, ai that generates headings, ai that generates titles, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, " +
      "summary of official documents, document summarization, ai text summarisation, summarizer, summerizer," +
      "ai seo writer, ai copywriting, ai copywriter, content automation, content curation,",
  },
  subs: {
    title: "Pricing for AI Writer, AI Story Writer, AI Summarizer, AI Paraphraser, AI Headline Generator",
    desc: "Subscribe to Sassbook AI Writer, AI Story Writer, AI Text " +
      "Summarizer, AI Paraphraser, and AI  Headline Generator to power your content " +
      "automation needs",
    keywords: "AI Writer, AI text generator, automatic text generator, summarizer, " +
      "writing ai, ai writing, best ai writer, summary generator, ai story writer, ai story generator, " +
      "artificial intelligence writer, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "ai headline generator, ai title generator, ai that generates headings, ai that generates titles, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, " +
      "summary of official documents, document summarization, ai text summarisation, summerizer," +
      "ai seo writer, ai copywriting, ai copywriter, content automation, content curation, story writing ai, " +
      "ai that writes stories, ai paraphrasing tool, ai paraphraser, ai rewriter, ai rewriting tool, " +
      "ai paraphrase, ai paraphrasing, ai that paraphrases, ai that rewrites",
  },
  check: {
    title: "Securely checkout AI Writer and AI Summarizer subscriptions",
    desc: "Secure, PCI-compliant subscription checkout page for the best AI Writer and AI Summarizer",
    keywords: "AI Writer, AI text generator, automatic text generator, summarizer," +
      "writing ai, ai writing, best ai writer, summary generator," +
      "artificial intelligence writer, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, " +
      "summary of official documents, document summarization, ai text summarisation, " +
      "ai seo writer, ai copywriting, ai copywriter, content automation, content curation,",
  },
  suml: {
    title: "About automatic text summarization with Sassbook AI summarizer",
    desc: "Sassbook AI Summarizer-the best AI-powered automatic text summarizer with rephrasing. Auto summarize " +
      "text just like a human would; for free.",
    keywords: "automatic summarizer, summary generator, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, automatic summary with rephrasing. " +
      "summarizing ai, ai abstract, ai document brief, academic document summaries, summary of teaching material, summary of study material, " +
      "summary of government documents, summary of official documents, document summarization, ai text summarisation, summarizer, summerizer",
  },
  hll: {
    title: "About AI title generation with Sassbook AI Headline Generator",
    desc: "Sassbook AI Headline Generator-the best AI-powered automatic headline " +
      "generator to give awesome titles for your article; for free",
    keywords: "best headline generator, best title generator, ai headline generator, " +
      "ai title generator, automatic headline generation, automatic title generation, " +
      " best tagline generator, ai tagline generator, automatic tagline generator, " +
      "blog title generator, catchy headline generator, article headline generator,  " +
      "seo title generator, seo description generator",
  },
  paral: {
    title: "About AI paraphrasing with Sassbook AI Paraphrase Tool",
    desc: "Sassbook AI Paraphraser-the best AI-powered AI online paraphrasing " +
      "generator to rewrite your articles like an expert; for free",
    keywords: "best paraphraser, best paraphrasing tool, ai paraphraser, " +
      "ai paraphrasing tool, best rewriter, best rewriting tool, " +
      "ai rewriter, ai rephraser, ai rephrasing tool, ai that paraphrases, " +
      "ai that rewrites, paraphrase sentences, rewrite sentences, " +
      "paraphrase paragraphs, rewrite paragraphs, reword text, rephrase text," +
      "ai paraphrases, quillbot paraphraser alternative, chatgpt paraphrase alternative, " +
      "paraphrasing tool online free, paraphrase generator, paraphrase online, paraphrasing " +
      "tool free, paraphrasing website, paraphrasing tool online, free paraphrasing, " +
      "control extent of paraphrase, change length of paraphrase, paraphrase variations, " +
      "article paraphraser, article paraphrasing ai, article rewriter, article " +
      "rewriter ai",
  },
  genl: {
    title: "Sassbook AI Writer | Free online AI text generator",
    desc: "The best automatic text generator that produces high quality, unique text content for free. " +
      "Quickly write a story, an article, a blog, or any type of content.",
    keywords: "AI Writer, AI text generator, automatic text generator, free ai text writer," +
      "writing ai, ai writing, best ai writer, artificial intelligence writer, AI content generator, " +
      "AI story writing, AI article writer, ai seo writer, ai copywriting, ai copywriter",
  },
  storyl: {
    title: "Sassbook AI Story Generator: The best AI Story Writer",
    desc: "The best automatic story writer generator that produces high quality, unique " +
      "fiction content. Free AI story writer with several story genres.",
    keywords: "AI Story Writer, AI story generator, automatic story generator, free ai story writer," +
      "story writing ai, ai story writing, best ai story writer, artificial intelligence story writer, " +
      "AI story generator, AI story writing, ai writing, ai fiction generator, AI short story writer, " +
      "ai generating stories, fiction writing ai",
  },
  rootl: {
    title: "Sassbook's AI Writer, Summarizer, Story Writer, and Title Generator explained",
    desc: "Sassbook AI automates content workflows, with products " +
      "like the AI Writer, AI Story Writer, and AI Text Summarizer, and the AI Headline Generator",
    keywords: "AI content automation, automatic content generation, ai automated content workflows, ai summarizer," +
      "ai content briefs, intelligent content authoring with ai, ai writer, ai text summarization, ai content curation," +
      "ai headline generator, ai title generator, ai that generates headings, ai that generates titles, " +
      "ai seo writer, ai copywriting, ai copywriter, summary generator, summarizer, summerizer," +
      "AI story writing, ai writing, ai fiction generator, AI article writer, " +
      "writing ai, fiction writing ai, story writing ai, ai that writes stories, " +
      "ai headline generator, ai title generator, ai tagline generator, " +
      "ai that generates headlines, ai that generates titles, headline generating ai, " +
      "title generating ai, ai paraphrasing tool, ai paraphraser, ai rewriter, ai rewriting tool, " +
      "ai paraphrase, ai paraphrasing, ai that paraphrases, ai that rewrites",
  },
  contact: {
    title: "AI Writer, Story Generator, Text Summarizer, Headline Generator contact",
    desc: "Contact us for Sassbook's AI automation software: the AI writer, " +
      "AI Story Writer, AI Text Summarizer, and AI Headline Generator",
    keywords: "AI Writer, AI text generator, automatic text generator, writing ai, ai writing, best ai writer, " +
      "artificial intelligence writer, AI content generator, AI story writing, AI article writer, " +
      "ai headline generator, ai title generator, ai that generates headings, ai that generates titles, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, summarizer, summerizer," +
      "summary of official documents, document summarization, ai text summarisation, " +
      "ai seo writer, ai copywriting, ai copywriter, ai story writer, ai story generator, " +
      "story writing ai, story generating ai, ai that writes stories, ai paraphrasing tool, " +
      "ai paraphraser, ai rewriter, ai rewriting tool, ai paraphrase, ai paraphrasing, " +
      "ai that paraphrases, ai that rewrites",
  },
  terms: {
    title: "Terms of use: Sassbook AI content automation online applications",
    desc: "The terms of use for Sassbook's content automation AI applications, specifically the AI Writer " +
      "and the AI automatic text summarizer software applications.",
    keywords: "AI Writer, AI text generator, automatic text generator, writing ai, ai writing, best ai writer, " +
      "artificial intelligence writer, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, summarizer, summerizer," +
      "ai seo writer, ai copywriting, ai copywriter, summary of official documents, " +
      "document summarization, ai text summarisation, ai paraphrasing tool, ai paraphraser, " +
      "ai rewriter, ai rewriting tool, ai paraphrase, ai paraphrasing, ai that paraphrases, ai that rewrites",
  },
  signin: {
    title: "Sign in to use Sassbook AI content automation smart applications",
    desc: "Sign in to use Sassbook's AI Writer, AI Story Writer, or AI Summary Generator to " +
      "generate text for free forever",
    keywords: "AI Writer, AI text generator, automatic text generator, summary generator, writing ai, " +
      "ai writing, best ai writer, artificial intelligence writer, AI content generator, AI story writing, " +
      "AI article writer, summary generator, automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, summarizer, summerizer, " +
      "summary of official documents, document summarization, ai text summarisation, " +
      "ai seo writer, ai copywriting, ai copywriter, ai story writer, ai story generator, " +
      "story writing ai, story generating ai, ai that writes stories, ai paraphrasing tool, " +
      "ai paraphraser, ai rewriter, ai rewriting tool, ai paraphrase, ai paraphrasing, " +
      "ai that paraphrases, ai that rewrites, article paraphraser, article paraphrasing ai, " +
      "article rewriter, article, rewriter ai",
  },
  privacy: {
    title: "Reliable AI writer, story writer, text summarizer, title generator",
    desc: "When you use the best AI writer, the best AI Story Writer, the " +
      "best AI Text summarizer, best AI Headline Generator, your privacy is protected.",
    keywords: "AI Writer, AI text generator, automatic text generator, writing ai, ai writing, best ai writer, " +
      "artificial intelligence writer, summary generator, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "ai headline generator, ai title generator, ai that generates headlines, ai that generates titles, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, summarizer, summerizer, " +
      "summary of official documents, document summarization, ai text summarisation, ai seo writer, ai copywriting, " +
      "ai copywriter, ai story writer, ai story generator, ai that generates stories, " +
      "ai paraphrasing tool, ai paraphraser, ai rewriter, ai rewriting tool, " +
      "ai paraphrase, ai paraphrasing, ai that paraphrases, ai that rewrites, article " +
      "paraphraser, article paraphrasing ai, article rewriter, article, rewriter ai",
  },
  signupconfirm: {
    title: "Confirm you email address for Sassbook AI",
    desc: "Verify your email to use Sassbook AI Writer or Sassbook AI Summarizer to generate text for free without " +
      "limits on the number of generations or lower size limits.",
    keywords: "AI Writer, AI text generator, automatic text generator, writing ai, ai writing, best ai writer, " +
      "artificial intelligence writer, , summary generator, AI content generator, AI story writing, AI article writer, " +
      "automatic summarizer, automated summaries, extractive summarization, abstractive summarization, " +
      "human-like ai summary, ai text summary, automatic text summary, ai summary with paraphrasing, " +
      "automatic summary with rephrasing, summarizing ai, ai abstract, ai document brief, academic document summaries, " +
      "summary of teaching material, summary of study material, summary of government documents, summarizer, summerizer, " +
      "summary of official documents, document summarization, ai text summarisation, ai seo writer, ai copywriting, ai copywriter",
  },
  dev: {
    title: "AI Text Summary Generator API and AI Content Generator API",
    desc: "Sassbook AI provides AI text summarization API and AI text content " +
      "generator API for automation of your content workflows.",
    keywords: "AI Summarization API, abstractive summary api, extractive summarization api, " +
      "summarizer API, auto summarizer API, Sassbook AI API, summary generator API, text summary API, " +
      "AI Writer API, AI content generator API, ai article writer API, " +
      "automatic text generator api, auto writer api, best ai writer api, " +
      "ai copy writing api, ai blog writer api, ai story writing api, " +
      "automatic text generator api, text generation API, ai paraphraser API, " +
      "ai paraphrasing API, ai rewriter API, ai rewriting API",
  },
};

const plans = {
  free: "free",
  standard: "standard",
  biz: "premium",
  premium: "premium",
  apistd: "apistd",
};

const planNames = {
  free: "Free",
  standard: "Standard",
  premium: "Premium",
  apistd: "Developer API",
};

const planIntervals = {
  monthly: "monthly",
  annual: "annual",
};

const planIntervalNames = {
  monthly: "Monthly",
  annual: "Annual",
};

const planLimits = {
  [plans.free]: {
    writer: {
      maxWordCount: 50,
      maxGensPerDay: 20,
      maxCandidateGens: 2,
    },
    summarizer: {
      maxWords: 1250,
      maxPages: 2.5,
      maxSumsPerDay: 15,
      fileSupport: false,
    },
    headliner: {
      maxWords: 250,
      maxPages: 0.5,
      maxHlPerDay: 10,
      maxCandidateGens: 2,
      fileSupport: false,
    },
    paraphraser: {
      maxWords: 150,
      maxPages: 0.3,
      maxGensPerDay: 10,
      maxCandidateGens: 1,
      fileSupport: false,
    },
  },
  [plans.standard]: {
    writer: {
      maxWordCount: 70,
      maxGensPerDay: -1,
      maxCandidateGens: 4,
    },
    summarizer: {
      maxWords: 5000,
      maxPages: 10,
      maxSumsPerDay: -1,
      fileSupport: true,
    },
    headliner: {
      maxWords: 800,
      maxPages: 1.6,
      maxHlPerDay: -1,
      maxCandidateGens: 5,
      fileSupport: false,
    },
    paraphraser: {
      maxWords: 5000,
      maxPages: 10,
      maxGensPerDay: -1,
      maxCandidateGens: 1,
      fileSupport: false,
    },
  },
  [plans.premium]: {
    writer: {
      maxWordCount: 100,
      maxGensPerDay: -1,
      maxCandidateGens: 6,
    },
    summarizer: {
      maxWords: 10000,
      maxPages: 20,
      maxSumsPerDay: -1,
      fileSupport: true,
    },
    headliner: {
      maxWords: 800,
      maxPages: 1.6,
      maxHlPerDay: -1,
      maxCandidateGens: 10,
      fileSupport: false,
    },
    paraphraser: {
      maxWords: 10000,
      maxPages: 20,
      maxGensPerDay: -1,
      maxCandidateGens: 10,
      fileSupport: false,
    },
  },
  [plans.apistd]: {
    writer: {
      maxWordCount: 100,
      maxGensPerDay: -1,
      maxCandidateGens: 6,
    },
    summarizer: {
      maxWords: 10000,
      maxPages: 20,
      maxSumsPerDay: -1,
      fileSupport: true,
    },
    headliner: {
      maxWords: 800,
      maxPages: 1.6,
      maxHlPerDay: -1,
      maxCandidateGens: 5,
      fileSupport: false,
    },
    paraphraser: {
      maxWords: 10000,
      maxPages: 20,
      maxGensPerDay: -1,
      maxCandidateGens: 10,
      fileSupport: false,
    },
  },
};

const products = {
  currency: "$",
  [plans.free]: {
    heading: planNames.free,
    id: plans.free,
    desc: "Basic feature set for the enthusiast",
    selected: false,
    aprice: 0,
    mprice: 0,
    maprice: 0,
    features: {
      writer: {
        "🔤 Words per generation": planLimits[plans.free].writer.maxWordCount,
        "🔤 Words per generation controllable": false,
        "⎇ Candidates per generation": planLimits[plans.free].writer.maxCandidateGens,
        "⎇ Candidates controllable": false,
        "⚙ Controllable generation behavior": false,
        "🚥 Generations per day": planLimits[plans.free].writer.maxGensPerDay,
        "🥇 Priority processing": false,
      },
      summer: {
        "🗐 Pages per summarization": planLimits[plans.free].summarizer.maxPages,
        "⚙ Configurable summarizer settings": false,
        "🚥 Summaries per day": planLimits[plans.free].summarizer.maxSumsPerDay,
        "🥇 Priority processing": false,
      },
      headliner: {
        "🗐 Pages per headline generation": planLimits[plans.free].headliner.maxPages,
        "⚙ Configurable headline generation settings": false,
        "⎇ Candidates per generation": planLimits[plans.free].headliner.maxCandidateGens,
        "🚥 Headline generations per day": planLimits[plans.free].headliner.maxHlPerDay,
        "🥇 Priority processing": false,
      },
      paraphraser: {
        "🗐 Pages per paraphrase generation": planLimits[plans.free].paraphraser.maxPages,
        "⚙ Configurable paraphraser settings": false,
        //        "⎇ Candidates per generation": planLimits[plans.free].paraphraser.maxCandidateGens,
        "🚥 Paraphrase generations per day": planLimits[plans.free].paraphraser.maxGensPerDay,
        "🥇 Priority processing": false,
      },
    },
  },
  [plans.standard]: {
    heading: planNames.standard,
    id: plans.standard,
    desc: "Full control and medium-sized content processing for individuals and light business use cases",
    selected: false,
    aprice: 390,
    maprice: 32.50,
    mprice: 39,
    apiCredit: 15,
    features: {
      writer: {
        "🔤 Words per generation": planLimits[plans.standard].writer.maxWordCount,
        "🔤 Words per generation controllable": true,

        "⎇ Candidates per generation": planLimits[plans.standard].writer.maxCandidateGens,
        "⎇ Candidates controllable": true,
        "⚙ Controllable generation behavior": true,
        "🚥 Generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      summer: {
        "🗐 Pages per summarization": planLimits[plans.standard].summarizer.maxPages,
        "⚙ Configurable summarizer settings": true,
        "🚥 Summaries per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      headliner: {
        "🗐 Pages per headline generation": planLimits[plans.standard].headliner.maxPages,
        "⚙ Configurable headline generation settings": true,
        "⎇ Candidates per generation": planLimits[plans.standard].headliner.maxCandidateGens,
        "🚥 Headline generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      paraphraser: {
        "🗐 Pages per paraphrase generation": planLimits[plans.standard].paraphraser.maxPages,
        "⚙ Configurable paraphraser settings": true,
        //        "⎇ Candidates per generation": planLimits[plans.standard].paraphraser.maxCandidateGens,
        "🚥 Paraphrase generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
    },
  },
  [plans.premium]: {
    heading: planNames.premium,
    id: plans.premium,
    desc: "Full control and large content processing for individual and " +
      "business use cases wanting full power and efficiency",
    selected: false,
    aprice: 590,
    maprice: 49.16,
    mprice: 59,
    apiCredit: 25,
    features: {
      writer: {
        "🔤 Words per generation": planLimits[plans.premium].writer.maxWordCount,
        "🔤 Words per generation controllable": true,
        "⎇ Candidates per generation": planLimits[plans.premium].writer.maxCandidateGens,
        "⎇ Candidates controllable": true,
        "⚙ Controllable generation behavior": true,
        "🚥 Generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      summer: {
        "🗐 Pages per summarization": planLimits[plans.premium].summarizer.maxPages,
        "⚙ Configurable summarizer settings": true,
        "🚥 Summaries per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      headliner: {
        "🗐 Pages per headline generation": planLimits[plans.premium].headliner.maxPages,
        "⚙ Configurable headline generation settings": true,
        "⎇ Candidates per generation": planLimits[plans.premium].headliner.maxCandidateGens,
        "🚥 Headline generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      paraphraser: {
        "🗐 Pages per paraphrase generation": planLimits[plans.premium].paraphraser.maxPages,
        "⚙ Configurable paraphraser settings": true,
        //        "⎇ Candidates per generation": planLimits[plans.premium].paraphraser.maxCandidateGens,
        "🚥 Paraphrase generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
    },
  },
  [plans.apistd]: {
    heading: planNames.apistd,
    id: plans.apistd,
    desc: "Standard access to prevailing official Sassbook AI developer API",
    selected: false,
    aprice: 25,
    maprice: 25,
    mprice: 25,
    features: {
      writer: {
        "🔤 Words per generation": planLimits[plans.premium].writer.maxWordCount,
        "🔤 Words per generation controllable": true,

        "⎇ Candidates per generation": planLimits[plans.premium].writer.maxCandidateGens,
        "⎇ Candidates controllable": true,
        "⚙ Controllable generation behavior": true,
        "🚥 Generations per day": "Unlimited",
      },
      summer: {
        "🗐 Pages per summarization": planLimits[plans.premium].summarizer.maxPages,
        "⚙ Configurable summarizer settings": true,
        "🚥 Summaries per day": "Unlimited",
        "🥇 Priority processing": true,
      },
      headliner: {
        "🗐 Pages per headline generation": planLimits[plans.premium].headliner.maxPages,
        "⚙ Configurable headline generation settings": true,
        "⎇ Candidates per generation": planLimits[plans.premium].headliner.maxCandidateGens,
        "🚥 Headline generations per day": planLimits[plans.premium].headliner.maxHlPerDay,
        "🥇 Priority processing": true,
      },
      paraphraser: {
        "🗐 Pages per paraphrase generation": planLimits[plans.premium].paraphraser.maxPages,
        "⚙ Configurable paraphraser settings": true,
        //        "⎇ Candidates per generation": planLimits[plans.premium].paraphraser.maxCandidateGens,
        "🚥 Paraphrase generations per day": "Unlimited",
        "🥇 Priority processing": true,
      },
    },
    trialDays: 7,
  },
};

const accountActions = {
  isUpgradingPlan: "isUpgradingPlan",
  isChangingQuantity: "isChangingQuantity",
  isUpgradingInterval: "isUpgradingInterval",
  checkoutQuantity: "checkoutQuantity",
  checkoutInterval: "checkoutInterval",
  isUpdatingCard: "isUpdatingCard",
};

const storyGenreTagNames = {
  original: "Original",
  classic: "Classic",
  humor: "Humor",
  scifi: "SciFi",
  romance: "Romance",
  thriller: "Thriller",
  realism: "Realism",
};

const isAppSubscribed = (plan) => [plans.standard, plans.premium].includes(plan);
const isApiSubscribed = (plan) => [plans.apistd].includes(plan);

if (typeof module === "object") {
  module.exports = {
    APP_CUR_REVISION, appLinks, appLinkTitles, learnLinks,
    learnLinkTitles, helmet, plans, accountActions, products,
    planNames, planIntervals, planIntervalNames, planLimits,
    storyGenreTagNames, isAppSubscribed, isApiSubscribed,
  };
}

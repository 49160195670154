import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { initializeApp, getApps } from "@firebase/app";
import { getAnalytics } from '@firebase/analytics';
import Loading from './common/Loading';
import loadable from '@loadable/component';
import { isEdgePrivateMode } from './lsutils';
import { AppContextProvider } from './AppContext';
import { useLocation } from "react-router-dom";
import * as config from './config';
import './App.css';

const Home = loadable(() => import("./Home"), {
  fallback: <Loading />,
});
const Checkout = loadable(() => import("./payments/Checkout"), {
  fallback: <Loading />,
});
const Writer = loadable(() => import("./generator/Writer"), {
  fallback: <Loading />,
});
const StoryWriter = loadable(() => import("./generator/story/Writer"), {
  fallback: <Loading />,
});
const Headliner = loadable(() => import("./headliner/Headliner"), {
  fallback: <Loading />,
});
const Summarizer = loadable(() => import("./summarizer/Summarizer"), {
  fallback: <Loading />,
});
const Paraphraser = loadable(() => import("./paraphraser/Paraphraser"), {
  fallback: <Loading />,
});
const Subscription = loadable(() => import("./payments/Subscription"), {
  fallback: <Loading />,
});
const Account = loadable(() => import("./Account"), {
  fallback: <Loading />,
});
const SignInOrHome = loadable(() => import("./SignInOrHome"), {
  fallback: <Loading />,
});
const SignUpConfirmation = loadable(() => import("./SignUpConfirmation"), {
  fallback: <Loading />,
});
const DeveloperAPI = loadable(() => import("./developer/DeveloperAPI"), {
  fallback: <Loading />,
});

const SummarizerAPI = loadable(() => import("./developer/SummarizerAPI"), {
  fallback: <Loading />,
});
const HeadlinerAPI = loadable(() => import("./developer/HeadlinerAPI"), {
  fallback: <Loading />,
});
const ParaphraserAPI = loadable(() => import("./developer/ParaphraserAPI"), {
  fallback: <Loading />,
});
const GeneratorAPI = loadable(() => import("./developer/GeneratorAPI"), {
  fallback: <Loading />,
});

const Privacy = loadable(() => import("./Privacy"), {
  fallback: <Loading />,
});
const Terms = loadable(() => import("./Terms"), {
  fallback: <Loading />,
});

const Contact = loadable(() => import("./Contact"), {
  fallback: <Loading />,
});
const Learn = loadable(() => import("./Learn"), {
  fallback: <Loading />,
});
const LearnGen = loadable(() => import("./generator/LearnGen"), {
  fallback: <Loading />,
});
const LearnStoryGen = loadable(() => import("./generator/story/LearnStoryGen"), {
  fallback: <Loading />,
});
const LearnSum = loadable(() => import("./summarizer/LearnSum"), {
  fallback: <Loading />,
});
const LearnHl = loadable(() => import("./headliner/LearnHl"), {
  fallback: <Loading />,
})
const LearnPara = loadable(() => import("./paraphraser/LearnPara"), {
  fallback: <Loading />,
})

/// On mobile, for example, the page scrolls to bottom
/// as the content is too long to fit the screen.
/// Scroll to top on mount only
///
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {

  const firebaseConfig = JSON.parse(process.env.REACT_APP_FB_CONFIG);

  if (getApps().length === 0) {
    let defApp = initializeApp(firebaseConfig);
    // let defApp = initializeApp({ projectId: "botbook-emu" });
    // if (window.location.hostname === "localhost") {
    //   firebase.firestore().settings({
    //     host: "localhost:12000",
    //     ssl: false
    //   });

    //   firebase.auth().useEmulator('http://localhost:9099/');
    // }
    if (process.env.REACT_APP_NEED_ANALYTICS === 'yes' && !isEdgePrivateMode()) {
      getAnalytics(defApp);
    }
  }

  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path="/signin/*" element={ <SignInOrHome /> } />
          <Route path={config.appLinks.gen} element={ <Writer /> } />
          <Route path={config.appLinks.story} element={ <StoryWriter /> } />
          <Route path={config.appLinks.account} element={ <Account /> } />
          <Route path={config.appLinks.subs} element={ <Subscription /> } />
          <Route exact path={config.appLinks.check} element={ <Checkout /> } />
          <Route path={`${config.appLinks.check}/:id`} element={ <Checkout /> } /> {/* For other workflows, upgrade etc */}
          <Route path={config.appLinks.signupconfirm} element={ <SignUpConfirmation /> } />
          <Route path={config.appLinks.sum} element={ <Summarizer /> } />
          <Route path={config.appLinks.hl} element={ <Headliner /> } />
          <Route path={config.appLinks.para} element={ <Paraphraser /> } />
          <Route path={config.learnLinks.root} element={ <Learn /> } />
          <Route path={config.learnLinks.gen} element={ <LearnGen /> } />
          <Route path={config.learnLinks.story} element={ <LearnStoryGen /> } />
          <Route path={config.learnLinks.sum} element={ <LearnSum /> } />
          <Route path={config.learnLinks.hl} element={ <LearnHl /> } />
          <Route path={config.learnLinks.para} element={ <LearnPara /> } />
          <Route path={config.appLinks.dev} element={  <DeveloperAPI /> } />
          <Route path={config.appLinks.sum_api} element={ <SummarizerAPI /> } />
          <Route path={config.appLinks.hl_api} element={ <HeadlinerAPI /> } />
          <Route path={config.appLinks.para_api} element={ <ParaphraserAPI /> } />
          <Route path={config.appLinks.gen_api} element={ <GeneratorAPI /> } />
          <Route path="/privacy" element={ <Privacy /> } />
          <Route path="/contact" element={ <Contact /> } />
          <Route path="/terms" element={ <Terms /> } />
          <Route path="/" element={ <Home />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export { App, ScrollToTop };

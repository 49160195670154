import React, { createContext, useReducer } from 'react';
import { getFromLS } from './lsutils';

const initialState = {
  keywords: getFromLS('keywords', ''),
  seedText: getFromLS('seedText', ''),
  storySeedText: getFromLS('storySeedText', ''),
  generated: getFromLS('prevGenerated', ''),
  storyGenerated: getFromLS('storyPrevGenerated', ''),
  prevGenerated: getFromLS('prevGenerated', ''),
  storyPrevGenerated: getFromLS('storyPrevGenerated', ''),
  genCreativity: getFromLS('genCreativity', 'var_med'),
  storyGenCreativity: getFromLS('storyGenCreativity', 'var_med'),
  genNumAlts: getFromLS('genNumAlts', 2), // Really the total
  storyGenNumAlts: getFromLS('storyGenNumAlts', 2), // Really the total
  genNumWords: getFromLS('genNumWords', 50),
  storyGenNumWords: getFromLS('storyGenNumWords', 50),
  storyGenCat: getFromLS("storyGenCat", "Original"),
  storyGenSubCat: getFromLS("storyGenSubCat", ""),

  // Independent of gen type
  numGens: getFromLS('numGens', 0),
  totalGenChars: getFromLS('totalGenChars', 0),
  appReturnURL: getFromLS('appReturnURL', '/'),
  appVerChecked: getFromLS('appVerChecked', 1612108800000),
  showSbAlert: getFromLS('showSbAlert', false),
  sbAlertMainText: getFromLS('sbAlertMainText', ''),

  // Transient stuff
  // For generic gen
  likeEnabled: getFromLS('likeEnabled', false),
  continueGen: getFromLS('continueGen', false),
  generating: getFromLS('generating', false),
  genToggle: getFromLS('genToggle', false),
  genError: getFromLS('genError', ''),
  genEngineMessage: getFromLS('genEngineMessage', ''),
  genMessage: getFromLS('genMessage', ''),
  genDbId: getFromLS('genDbId', ''),
  genPrevCode: getFromLS('genPrevCode', ''),
  genList: getFromLS('genList', []),
  genSel: getFromLS('genSel', -1),
  // For story gen
  storyLikeEnabled: getFromLS('storyLikeEnabled', false),
  storyContinueGen: getFromLS('storyContinueGen', false),
  storyGenerating: getFromLS('storyGenerating', false),
  storyGenToggle: getFromLS('storyGenToggle', false),
  storyGenError: getFromLS('storyGenError', ''),
  storyGenEngineMessage: getFromLS('storyGenEngineMessage', ''),
  storyGenMessage: getFromLS('storyGenMessage', ''),
  storyGenDbId: getFromLS('storyGenDbId', ''),
  storyGenPrevCode: getFromLS('storyGenPrevCode', ''),
  storyGenList: getFromLS('storyGenList', []),
  storyGenSel: getFromLS('storyGenSel', -1),

  user: getFromLS('user', {}),

  // Summarizer related:
  sumSrcText: getFromLS('sumSrcText', ''),
  sumSrcHash: getFromLS('sumSrcHash', ''),
  sumList: getFromLS('sumList', []),
  summary: getFromLS('summary', ''),
  sumTarget: getFromLS('sumTarget', 'best'),
  sumTargetPercent: getFromLS('sumTargetPercent', 16),
  sumMethod: getFromLS('sumMethod', 'abs'),
  numSums: getFromLS('numSums', 0),
  totalSumWords: getFromLS('totalSumWords', 0),
  sumDemoObj: getFromLS('sumDemoObj', {}),

  // Summarizer transient stuff
  sumLikeEnabled: getFromLS('sumLikeEnabled', false),
  summarizing: getFromLS('summarizing', false),
  sumToggle: getFromLS('sumToggle', false),
  sumError: getFromLS('sumError', ''),
  sumMessage: getFromLS('sumMessage', ''),
  sumEngineMessage: getFromLS('sumEngineMessage', ''),
  sumDbId: getFromLS('sumDbId', ''),
  sumDemoMode: getFromLS('sumDemoMode', false),
  sumXAbs: getFromLS('sumXAbs', true),

  // Headliner related:
  hlSrcText: getFromLS('hlSrcText', ''),
  hlSrcHash: getFromLS('hlSrcHash', ''),
  headlines: getFromLS('headlines', []),
  hlTarget: getFromLS('hlTarget', 20),
  hlHeat: getFromLS('hlHeat', 20),
  hlNumAlts: getFromLS('hlNumAlts', 2),
  numHls: getFromLS('numHls', 0),

  // Headliner transient stuff
  hlLikeEnabled: getFromLS('hlLikeEnabled', false),
  headlining: getFromLS('headlining', false),
  hlToggle: getFromLS('hlToggle', false),
  hlError: getFromLS('hlError', ''),
  hlMessage: getFromLS('hlMessage', ''),
  hlEngineMessage: getFromLS('hlEngineMessage', ''),
  hlDbId: getFromLS('hlDbId', ''),

  // Paraphraser related:
  paraSrcText: getFromLS('paraSrcText', ''),
  paraSrcHash: getFromLS('paraSrcHash', ''),
  paraphrases: getFromLS('paraphrases', []),
  paraTarget: getFromLS('paraTarget', "keep"),
  paraVariance: getFromLS('paraVariance', "medium"),
  paraNumAlts: getFromLS('paraNumAlts', 1),
  numParas: getFromLS('numPara', 0),

  // Paraphraser transient stuff
  paraLikeEnabled: getFromLS('paraLikeEnabled', false),
  paraphrasing: getFromLS('paraphrasing', false),
  paraError: getFromLS('paraError', ''),
  paraMessage: getFromLS('paraMessage', ''),
  paraDbId: getFromLS('paraDbId', ''),

  // Payment/subscription
  selectedPlan: getFromLS('selectedPlan', { period: 'annual' }),
};

const AppContext = createContext();

const appReducer = (state, action) => {

  const lssetter = (state, action, propName, isObj = false) => {
    localStorage.setItem(propName,
      isObj ? JSON.stringify(action.payload) : action.payload);
    state[propName] = action.payload;
    //return { ...state, propName: action.payload }; // Sets 'propName'!
    //console.log('lssetter: ', state, propName,action.payload, isObj);
    return { ...state }; // Gotta return a new object to trigger updates
  };

  switch (action.type) {
    case 'setAppReturnURL':
      {
        return lssetter(state, action, 'appReturnURL');
      }
    case 'setAppVerChecked':
      {
        return lssetter(state, action, 'appVerChecked');
      }
    case 'setShowSbAlert':
      {
        return lssetter(state, action, 'showSbAlert');
      }
    case 'setSbAlertMainText':
      {
        return lssetter(state, action, 'sbAlertMainText');
      }
    case "setKeywords":
      {
        return lssetter(state, action, 'keywords');
      }
    case "setSeedText":
      {
        return lssetter(state, action, 'seedText');
      }
    case "setStorySeedText":
      {
        return lssetter(state, action, 'storySeedText');
      }
    case "setGenerated":
      {
        return lssetter(state, action, 'generated');
      }
    case "setStoryGenerated":
      {
        return lssetter(state, action, 'storyGenerated');
      }
    case "setPrevGenerated":
      {
        return lssetter(state, action, 'prevGenerated');
      }
    case "setStoryPrevGenerated":
      {
        return lssetter(state, action, 'storyPrevGenerated');
      }
    case "setNumGens": // Shared across gens
      {
        return lssetter(state, action, 'numGens');
      }
    case "setGenCreativity":
      {
        return lssetter(state, action, 'genCreativity');
      }
    case "setStoryGenCreativity":
      {
        return lssetter(state, action, 'storyGenCreativity');
      }
    case "setGenNumAlts":
      {
        return lssetter(state, action, 'genNumAlts');
      }
    case "setStoryGenNumAlts":
      {
        return lssetter(state, action, 'storyGenNumAlts');
      }
    case "setGenNumWords":
      {
        return lssetter(state, action, 'genNumWords');
      }
    case "setStoryGenNumWords":
      {
        return lssetter(state, action, 'storyGenNumWords');
      }
    case "setTotalGenChars": // Shared across gens
      {
        return lssetter(state, action, 'totalGenChars');
      }
    case "setContinueGen":
      {
        return lssetter(state, action, 'continueGen');
      }
    case "setStoryContinueGen":
      {
        return lssetter(state, action, 'storyContinueGen');
      }
    case "setGenerating":
      {
        return lssetter(state, action, 'generating');
      }
    case "setStoryGenerating":
      {
        return lssetter(state, action, 'storyGenerating');
      }
    case "setGenToggle":
      {
        return lssetter(state, action, 'genToggle');
      }
    case "setStoryGenToggle":
      {
        return lssetter(state, action, 'storyGenToggle');
      }
    case "setGenError":
      {
        return lssetter(state, action, 'genError');
      }
    case "setStoryGenError":
      {
        return lssetter(state, action, 'storyGenError');
      }
    case "setGenMessage":
      {
        return lssetter(state, action, 'genMessage');
      }
    case "setGenEngineMessage":
      {
        return lssetter(state, action, 'genEngineMessage');
      }
    case "setStoryGenEngineMessage":
      {
        return lssetter(state, action, 'storyGenEngineMessage');
      }
    case "setGenDbId":
      {
        return lssetter(state, action, 'genDbId');
      }
    case "setStoryGenDbId":
      {
        return lssetter(state, action, 'storyGenDbId');
      }
    case "setGenPrevCode":
      {
        return lssetter(state, action, 'genPrevCode');
      }
    case "setStoryGenPrevCode":
      {
        return lssetter(state, action, 'storyGenPrevCode');
      }
    case "setLikeEnabled":
      {
        return lssetter(state, action, 'likeEnabled');
      }
    case "setStoryLikeEnabled":
      {
        return lssetter(state, action, 'storyLikeEnabled');
      }
    case "setGenList":
      {
        return lssetter(state, action, 'genList', true);
      }
    case "setStoryGenList":
      {
        return lssetter(state, action, 'storyGenList', true);
      }
    case "setGenSel":
      {
        return lssetter(state, action, 'genSel');
      }
    case "setStoryGenSel":
      {
        return lssetter(state, action, 'storyGenSel');
      }
    case "setStoryGenCat":
      {
        return lssetter(state, action, 'storyGenCat');
      }
    case "setStoryGenSubCat":
      {
        return lssetter(state, action, 'storyGenSubCat');
      }
    case "setUser":
      {
        return lssetter(state, action, 'user', true);
      }

    // Summarizer related state
    case 'setSumSrcText':
      return lssetter(state, action, 'sumSrcText');
    case 'setSumSrcHash':
      return lssetter(state, action, 'sumSrcHash');
    case 'setSumList':
      return lssetter(state, action, 'sumList', true);
    case 'setSummary':
      return lssetter(state, action, 'summary');
    case 'setSumTarget':
      return lssetter(state, action, 'sumTarget');
    case 'setSumTargetPercent':
      return lssetter(state, action, 'sumTargetPercent');
    case 'setSumMethod':
      return lssetter(state, action, 'sumMethod');
    case 'setNumSums':
      return lssetter(state, action, 'numSums');
    case 'setTotalSumWords':
      return lssetter(state, action, 'totalSumWords');
    case 'setSumLikeEnabled':
      return lssetter(state, action, 'sumLikeEnabled');
    case 'setSummarizing':
      return lssetter(state, action, 'summarizing');
    case 'setSumToggle':
      return lssetter(state, action, 'sumToggle');
    case 'setSumError':
      return lssetter(state, action, 'sumError');
    case 'setSumMessage':
      return lssetter(state, action, 'sumMessage');
    case 'setSumEngineMessage':
      return lssetter(state, action, 'sumEngineMessage');
    case 'setSumDbId':
      return lssetter(state, action, 'sumDbId');
    case 'setSumDemoMode':
      return lssetter(state, action, 'sumDemoMode');
    case 'setSumDemoObj':
      return lssetter(state, action, 'sumDemoObj', true);
    case 'setSumXAbs':
      return lssetter(state, action, 'sumXAbs');

    // Headliner related state
    case 'setHlSrcText':
      return lssetter(state, action, 'hlSrcText');
    case 'setHlSrcHash':
      return lssetter(state, action, 'hlSrcHash');
    case 'setHeadlines':
      return lssetter(state, action, 'headlines', true);
    case 'setHlTarget':
      return lssetter(state, action, 'hlTarget');
    case 'setHlHeat':
      return lssetter(state, action, 'hlHeat');
    case 'setHlNumAlts':
      return lssetter(state, action, 'hlNumAlts');
    case 'setNumHls':
      return lssetter(state, action, 'numHls');
    case 'setHlLikeEnabled':
      return lssetter(state, action, 'hlLikeEnabled');
    case 'setHeadlining':
      return lssetter(state, action, 'headlining');
    case 'setHlToggle':
      return lssetter(state, action, 'hlToggle');
    case 'setHlError':
      return lssetter(state, action, 'hlError');
    case 'setHlMessage':
      return lssetter(state, action, 'hlMessage');
    case 'setHlEngineMessage':
      return lssetter(state, action, 'hlEngineMessage');
    case 'setHlDbId':
      return lssetter(state, action, 'hlDbId');

    // Paraphraser related state
    case 'setParaSrcText':
      return lssetter(state, action, 'paraSrcText');
    case 'setParaSrcHash':
      return lssetter(state, action, 'paraSrcHash');
    case 'setParaphrases':
      return lssetter(state, action, 'paraphrases', true);
    case 'setParaTarget':
      return lssetter(state, action, 'paraTarget');
    case 'setParaVariance':
      return lssetter(state, action, 'paraVariance');
    case 'setParaNumAlts':
      return lssetter(state, action, 'paraNumAlts');
    case 'setNumParas':
      return lssetter(state, action, 'numParas');
    case 'setParaLikeEnabled':
      return lssetter(state, action, 'paraLikeEnabled');
    case 'setParaphrasing':
      return lssetter(state, action, 'paraphrasing');
    case 'setParaError':
      return lssetter(state, action, 'paraError');
    case 'setParaMessage':
      return lssetter(state, action, 'paraMessage');
    case 'setParaDbId':
      return lssetter(state, action, 'paraDbId');

    // Payment/Subscription
    case 'setSelectedPlan':
      return lssetter(state, action, 'selectedPlan', true);
    default:
      console.assert("Unknown setState call: Check!");
      return state;
  }
};

const AppContextProvider = props => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };

import isEmpty from 'lodash-es/isEmpty';

// Util function to get from LS
const getFromLS = (key, defVal) => {
  let tempVal = localStorage.getItem(key);
  let val;
  switch (typeof defVal) {
    case 'string':
      val = tempVal;
      break;
    case 'number':
      val = Number.isInteger(defVal) ? parseInt(tempVal) : parseFloat(tempVal);
      break;
    case 'boolean':
      val = tempVal === 'true';
      break;
    case 'object':
      val = JSON.parse(tempVal);
      break;
    default:
      console.log("Unexpected type in getFromLS");
  }
  return val || defVal;
};

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

/// Validate the writer input for length. Only one of the inputs will be
/// non-empty
const validateWriterInput = (sentSeedTxt, prevGenerated, writerType) => {
      // See if seed text is within limits (200 words, 1000 chars)
      const maxSeedLen = 1000;
      const writerOutputLabel = writerType === "writer" ? "Article generated" :
      "Story Generated"; // The other one is story-writer
      let errTxt = "";
      if (sentSeedTxt?.length > maxSeedLen) {
        errTxt = `The prompt length should not exceed ${maxSeedLen} characters. ` +
        'Please use a small number of sentences to generate completions.\n\nIf ' +
        'you want to generate from the end of existing content, use the ' +
        `"${writerOutputLabel}" field and follow the Continue workflow.`;

        return [false, errTxt];
      }

      const curArticleLength = prevGenerated?.length;
      if (curArticleLength > 100000) {
        errTxt = `The "${writerOutputLabel}" field cannot exceed 100000 ` +
          `characters (or about 40 pages of text!). You now have ${curArticleLength} ` +
          "characters. Copy any existing content to your favorite text editor, and " +
          "only keep the last several pages before hitting the Continue button.";
        return [false, errTxt];
      }
      return [true, errTxt];
};

///Detect whether we are running in Edge private mode where indexed DB is disabled
/// and causes analytics() to error out
/// 
const isEdgePrivateMode = () => !window.indexedDB &&
  (window.PointerEvent || window.MSPointerEvent);

const messageFromAxiosError = (axiosError) => {

  let errTxt = '';
  let errCode = 0;

  if (axiosError.response) {
    errTxt = axiosError.response.data;
    errCode = axiosError.response.status;
  }
  else if (axiosError.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and
    // an instance of http.ClientRequest in node.js
    errTxt = axiosError.request.toString();
    errCode = 408;
  }
  else {
    // Something happened in setting up the request that triggered an Error
    errTxt = axiosError.message;
  }

  console.log("The error text was ", errTxt);
  switch (errCode) {
    case 0:
      return `Something went wrong while setting up the server request. Please let
        us know by quoting the message below:
        
        ${errTxt}`;

    case 401:
      if (errTxt === 'UNVERIFIED_USER') {
        return `Email verification is required for completing the operation.
          Please verify your email by following the link in the verification email Sassbook AI sent to you.`;
      }
      return 'You are unauthorized to perform this action. If you believe this message is in error, please ' +
        'email us to resolve it.';

    case 404:
      return `Sorry, the server was unreachable. Please check your Internet
        connection and try again later.`;

    case 408: // Client request error
      return `There was no response from the server. Please try again later.`;

    case 422: // Invalid input
      if (typeof errTxt === 'object' && errTxt.code === 422) {
        return errTxt.message; // Message from server
      }
      return `There was a problem with your input. Please check and supply valid input.`;

    case 429: // Too many requests
      if (typeof errTxt === 'string' && errTxt === 'LTD_UNVERIFIED_USER') {
        return `There were too many requests from your site as an unverified user.
        Please try again later or use a verified user account to avoid this limitation.`;
      }
      else if (typeof errTxt === 'object' && errTxt.code === 429) {
        return errTxt.message; // Message from server
      }
      return `There were too many requests from your site recently. Please try again later.`;

    case 502:
      return `The request timed out. It appears that the server is experiencing
        high load. Please try again after some time.`;

    case 500:
      if (typeof errTxt === 'object' && errTxt.code === 500) {
        return errTxt.message; // Message from server
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return `The server encountered an error and could not complete the request.
          Please try again after some time.`;
  }
};

const fbUserToSbUser = (fbUser) => {
  if (isEmpty(fbUser)) {
    return {};
  }

  const fullName = fbUser.displayName || (fbUser.providerData.length > 0 ?
    fbUser.providerData[0].displayName : '');
  const photoURL = fbUser.photoURL || (fbUser.providerData.length > 0 ?
    fbUser.providerData[0].photoURL : '');

  return {
    name: fullName,
    photoURL: photoURL,
    email: fbUser.email,
    isAnon: fbUser.isAnonymous,
    uid: fbUser.uid,
  }
};

const isSignedIn = (stateObj) => {
  return !(isEmpty(stateObj.user) || stateObj.user.isAnon);
};

const isSignedInUser = (userObj) => {
  return !(isEmpty(userObj) || userObj.isAnon);
};

const getAppFullURL = () => {
  const fbConfig = JSON.parse(process.env.REACT_APP_FB_CONFIG);
  return `https://${fbConfig.authDomain}`; // Like, https://sassbook.com
};

// Sort multiple subscriptions in descending order. so the first element is latest
//
const sortSubsAsc = (docChanges) => {
  const getSeconds = (x) => {
    const updated = x.doc.data() ? x.doc.data().updated : null;
    return updated ? updated.seconds : 0;
  };

  docChanges.sort((a, b) => {
    return getSeconds(b) - getSeconds(a);
  });
};

const reEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export {
  getFromLS,
  getAppFullURL,
  isMobile,
  reEmail,
  isEdgePrivateMode,
  messageFromAxiosError,
  fbUserToSbUser,
  isSignedIn,
  isSignedInUser,
  sortSubsAsc,
  validateWriterInput,
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

// Control logging, could be simplified
// The stripe key determines whether to log or not. Disable in live environments.
const DEBUG = process.env.REACT_APP_SHOP_PK.startsWith('pk_test_');// Test PK
const old_console_log = console.log;
console.log = function () {
  if (DEBUG) {
    old_console_log.apply(this, arguments);
  }
};
const old_console_info = console.info;
console.info = function () {
  if (DEBUG) {
    old_console_info.apply(this, arguments);
  }
};

const old_console_debug = console.debug;
console.debug = function () {
  if (DEBUG) {
    old_console_debug.apply(this, arguments);
  }
};

const old_console_warn = console.warn;
console.warn = function () {
  if (DEBUG) {
    old_console_warn.apply(this, arguments);
  }
};

const old_console_error = console.error;
console.error = function () {
  if (DEBUG) {
    old_console_error.apply(this, arguments);
  }
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
